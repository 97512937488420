export default {
  "website_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aldgailbi & Altalhi"])},
  "more_about_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["more about us"])},
  "about_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["about us"])},
  "follow_on_socials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["follow on socials"])},
  "useful_links": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["useful links"])},
  "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["home"])},
  "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["about"])},
  "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["services"])},
  "blogs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["blogs"])},
  "our_practice_area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["our practice area"])},
  "more_services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["more services"])},
  "team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["team"])},
  "meet_our_advisors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["meet our advisors"])},
  "our_blogs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["our blogs"])},
  "more_blogs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["more blogs"])},
  "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lang"])},
  "how_we_can_help_you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["how we can help you"])},
  "message_sent_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mesage sent successfully"])},
  "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contact us"])},
  "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contact"])},
  "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["phone"])},
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["message"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["address"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["email"])},
  "marwan_tech": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["marwan tech"])},
  "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["success"])},
  "wrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["wrong"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["name"])},
  "error404": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["error 404"])},
  "page_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["page not found"])},
  "sending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sending"])},
  "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["send"])},
  "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["description"])},
  "results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["results"])},
  "oops": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["oops"])},
  "no_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["no results"])},
  "recapcha_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["select recapcha first"])},
  "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["date"])},
  "thanks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["thanks"])},
  "all_blogs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["all blogs"])},
  "all_services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["all services"])},
  "AboutView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["about us"])},
  "BlogsView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["blogs"])},
  "SingleBlogView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["single blog"])},
  "ServicesView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["services"])},
  "SingleServiceView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["single service"])},
  "TeamView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["team"])},
  "ContactView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contact"])},
  "Error404": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["error 404"])},
  "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
}